import React from "react"

import { withCustomerDetailsForm } from "./withCustomerDetailsForm"
import { Form, FormSubheading, FormLabel, FormFields, FormButton, FormButtonWrapper, FormError } from "../../../Styled/Form"
import { CustomerDetailsFormField } from "../Field/CustomerDetailsFormField"
import { CustomerDetailsFormWedding } from "../Wedding/CustomerDetailsFormWedding"

interface Props {
  changed: boolean
  customer: any
  errors: any
  fields: Array<any>
  handleChange: any
  handleWeddingChange: any
  handleSubmit: any
  loading: boolean
  locales: any
  wedding: any
}

export const CustomerDetailsForm = withCustomerDetailsForm(
  ({ changed, customer, errors, fields, handleChange, handleWeddingChange, handleSubmit, loading, locales, wedding }: Props) => (
    <Form onSubmit={handleSubmit} margin={`true`}>
      <FormSubheading>{locales?.title}</FormSubheading>

      <FormFields>
        {fields.map(field => (
          <CustomerDetailsFormField key={field.name} customer={customer} field={field} handleChange={handleChange} readOnly={field?.readOnly} />
        ))}
      </FormFields>

      <FormLabel>{locales?.detailsWeddingDate}</FormLabel>
      <CustomerDetailsFormWedding date={wedding} handleChange={handleWeddingChange} loading={loading} />

      <FormButtonWrapper>
        <FormButton disabled={loading || !changed} type={`submit`}>
          {locales?.save}
        </FormButton>
      </FormButtonWrapper>

      {errors?.length > 0 && errors?.map(({ code, message }) => <FormError key={code}>{message}</FormError>)}
    </Form>
  )
)
