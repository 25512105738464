import React from "react"

import { withCustomerDetailsFormWedding } from "./withCustomerDetailsFormWedding"
import { FormInput, FormInputs, FormInputItem } from "../../../Styled/Form"

interface Props {
  date: any
  fields: Array<any>
  handleChange: any
}

export const CustomerDetailsFormWedding = withCustomerDetailsFormWedding(({ date, fields, handleChange }: Props) => (
  <FormInputs>
    {fields.map(field => (
      <FormInputItem key={field.name}>
        <FormInput
          maxLength={field.maxchars}
          name={field.name}
          placeholder={field.label.toUpperCase()}
          onChange={handleChange}
          value={date?.[field.name] || ``}
        />
      </FormInputItem>
    ))}
  </FormInputs>
))
