import React, { useState, useEffect } from "react"

import { useApp } from "@hooks/useApp"
import { useCustomerDetails } from "@hooks/useCustomer"
import { useFunctions } from "@hooks/useFunctions"
import { useLanguage } from "@hooks/useLanguage"

export const withCustomerDetailsForm = Component => ({ name = `CustomerDetailsForm` }) => {
  const {
    config: { settings },
  } = useApp()
  const { customer, setCustomer, savedCustomer, updateCustomer, loading, saving, errors } = useCustomerDetails()
  const { customerTags } = useFunctions()
  const [wedding, setWedding] = useState(null)
  const locales = {
    ...useLanguage(`account`),
    ...useLanguage(`form`),
  }

  const fields = [
    {
      name: "firstName",
      label: locales?.firstName,
      required: true,
    },
    {
      name: "lastName",
      label: locales?.lastName,
      required: true,
    },
    {
      name: "email",
      label: locales?.emailAddress,
      readOnly: true,
    },
  ]
  const date =
    customer?.tags &&
    Object.values(settings.products.delivery.fields).reduce(
      (object, field, index) => ({
        ...object,
        [field?.name]:
          customer?.tags
            ?.find(tag => tag?.startsWith(settings.products.delivery.weddingTag))
            ?.replace(settings.products.delivery.weddingTag, ``)
            ?.split(`-`)?.[index] || null,
      }),
      {}
    )

  const changed = fields.filter(field => customer?.[field?.name] !== savedCustomer?.[field?.name])
  const weddingChanged = Object.values(settings.products.delivery.fields).filter(field => wedding?.[field?.name] !== date?.[field?.name])

  const handleSubmit = async event => {
    event.preventDefault()

    await updateCustomer(customer)

    if (weddingChanged) {
      const newTags = customer?.tags?.filter(tag => !tag?.includes(settings.products.delivery.weddingTag)) || []
      const newDate = Object.values(settings.products.delivery.fields)
        .map(field => wedding?.[field?.name])
        ?.join(`-`)
      const newDateTag = `${settings.products.delivery.weddingTag}${newDate}`

      await customerTags([...newTags, newDateTag])
    }
  }

  const handleWeddingChange = ({ target: { type, name, value, checked } }) =>
    setWedding(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))

  const handleChange = ({ target: { type, name, value, checked } }) =>
    setCustomer(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))

  useEffect(() => {
    if (!wedding && date) {
      setWedding(date)
    }
  }, [date])

  Component.displayName = name
  return (
    <Component
      changed={changed?.length || weddingChanged?.length}
      customer={customer}
      errors={errors}
      fields={fields}
      handleChange={handleChange}
      handleWeddingChange={handleWeddingChange}
      handleSubmit={handleSubmit}
      locales={locales}
      loading={loading || saving}
      wedding={wedding}
    />
  )
}
